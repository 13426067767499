export const contract = {
    // 97 : {
    //     poolmanager : "0xd4A16EA72a4E8cCc8181882E774b1746793B508a",
    //     poolFactory : "0xd20b3E863449Fd896A9Db0Feb9069873c86910C6",
    //     multicallAddress : "0xa54fe4a3dbd1eb21524cd73754666b7e13b4cb18"
    // },
    1 : {
        poolmanager : "0xE2331ff74b5930d7084723082dC4E0B0B502eD23",
        poolFactory : "0xee52fe740b40FA92D2bb639dc5Cb41f602688b89",
        multicallAddress : "0x5e227AD1969Ea493B43F840cfF78d08a6fc17796"
    },
    56: {
        poolmanager: "0xAbB0FFB2d1Dd881c39Ab9C67F19fE9748C3f2969",
        poolFactory: "0x22A937087933D3d5C0542Ec3EB780c0333D8D853",
        multicallAddress: "0x2cc8fe3e04d7b1edaeb42e2e73f78c4cac074116"
    },
    137: {
        poolmanager: "0x8a30f81e416c95a3028a56c4d39f28ee76f224be",
        poolFactory: "0xD8771bF13ef96a6e86E6C7989F366f3C7aC63dB6",
        multicallAddress: "0x4d76592d83585cf00792057749b03f97964668e8"
    },
    43114 : {
        poolmanager: "0x522F595dA6Ac21134F82b390C124CBE3089A2Bc5",
        poolFactory: "0x9Eb57fC71dF47b93eE86223f9e92d394403124B5",
        multicallAddress: "0xcA11bde05977b3631167028862bE2a173976CA11"
    },
    // 4: {
    //     poolmanager: "0x47b1b0d9E5aD3dE4D1dec9f6a21b49B0B69cdC50",
    //     poolFactory: "0x20a0fe66101F6A9c01FD57990E5E8999985476ff",
    //     multicallAddress: "0xb05097864b5efea696a9884eb0deb0d0c2ad190f"
    // },
    'default': {
        poolmanager: "0xAbB0FFB2d1Dd881c39Ab9C67F19fE9748C3f2969",
        poolFactory: "0x22A937087933D3d5C0542Ec3EB780c0333D8D853",
        multicallAddress: "0x2cc8fe3e04d7b1edaeb42e2e73f78c4cac074116"
    }

}

