import React, { useEffect, useContext } from 'react'
import Footer from "./component/Footer";
import Header from "./component/Header";
import ScrollToTop from "./component/ScrollToTop";
import Dashboard from "./pages/Dashboard";
import AddPartner from "./pages/partner/AddPartner";
import PartnerView from "./pages/partner/PartnerView";
import Profile from "./pages/Profile";
import Login from "./pages/Login";
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import localStorage from "local-storage";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import Context from "./context/Context";
import EditPartner from './pages/partner/EditPartner';
import PartnerProfile from './pages/PartnerProfile';
import PartnerDetails from './pages/PartnerDetails';
import VerifyKycAudit from './pages/VerifyKycAudit';
import SetAdmin from './pages/SetAdmin';
import KycAuditStatus from './pages/KycAuditStatus';
import PartnerTransaction from './pages/partner/PartnerTransaction';
import Featuredpools from './pages/Featuredpools';

export default function Main() {
    const { value, setValue } = useContext(Context);

    useEffect(() => {
        async function checkLogin() {
            let token = localStorage.get('token');
            let response = await axios.post(`${process.env.REACT_APP_API_URL}api/admin/verify-token`, { token });
            if (typeof response.data !== 'undefined' && response.data.error === 'OK') {
                setValue({ ...value, login: true, usertype: response.data.data.user ? response.data.data.user.usertype : 2 });
                localStorage.set('login', 'true');
            }
            else {
                toast.error(response.data.error);
                setValue({ login: false });
                if (localStorage.get('login') === 'true') {
                    localStorage.clear();
                    window.location.href = window.location.origin;
                }
            }
        }

        checkLogin();
        // eslint-disable-next-line
    }, [])
    return (
        <React.Fragment>
            {
                value.login ? (
                    value.usertype === 1 ? (
                        <Router>
                            <ScrollToTop>
                                <ToastContainer pauseOnFocusLoss={false} />

                                <Header />
                                <Switch>
                                    <Route exact path="/">
                                        <Dashboard />
                                    </Route>
                                    <Route exact path="/profile">
                                        <Profile />
                                    </Route>
                                    <Route exact path="/partner-view">
                                        <PartnerView />
                                    </Route>
                                    <Route exact path="/add-partner">
                                        <AddPartner />
                                    </Route>
                                    <Route exact path="/edit-partner/:id">
                                        <EditPartner />
                                    </Route>
                                    <Route exact path="/verify-kyc-audit">
                                        <VerifyKycAudit />
                                    </Route>
                                    <Route exact path="/set-admin">
                                        <SetAdmin />
                                    </Route>
                                    <Route exact path="/audit-kyc-staus">
                                        <KycAuditStatus />
                                    </Route>
                                    <Route exact path="/partner-transaction">
                                        <PartnerTransaction />
                                    </Route>
                                    <Route exact path="/featured-pools">
                                        <Featuredpools />
                                    </Route>

                                </Switch>
                                <Footer />
                            </ScrollToTop>
                        </Router>
                    ) :
                        (
                            <Router>
                                <ScrollToTop>
                                    <ToastContainer pauseOnFocusLoss={false} />

                                    <Header />
                                    <Switch>
                                        <Route exact path="/">
                                            <Dashboard />
                                        </Route>
                                        <Route exact path="/profile">
                                            <PartnerProfile />
                                        </Route>
                                        <Route exact path="/details">
                                            <PartnerDetails />
                                        </Route>
                                        <Route exact path="/partner-transaction">
                                            <PartnerTransaction />
                                        </Route>
                                    </Switch>
                                    <Footer />
                                </ScrollToTop>
                            </Router>
                        )
                ) : (
                    <Router>
                        <ScrollToTop>
                            <ToastContainer pauseOnFocusLoss={false} />
                            <Switch>
                                <Route exact path="/">
                                    <Login />
                                </Route>
                            </Switch>
                        </ScrollToTop>
                    </Router>
                )
            }
        </React.Fragment>
    )
}
