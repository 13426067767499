import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { formatUnits } from 'ethers/lib/utils';
import localStorage from 'local-storage';
import { supportNetwork } from '../../helper/network';


export default function PartnerView() {
    let [partnerArray, setPartnerArray] = useState([]);
    let [refresh, setRefresh] = useState(new Date());

    useEffect(() => {
        async function fetchData() {
            let partnerData = await axios.get(`${process.env.REACT_APP_API_URL}api/admin/get-partner`);
            if (typeof partnerData.data !== 'undefined' && partnerData.data.error === 'OK') {
                setPartnerArray(partnerData.data.data)
            }
            else {
                toast.error(partnerData.data.error);
            }
        }
        fetchData();
    }, [refresh]);


    const handleIsActive = async (e, isActive, address) => {
        let token = localStorage.get('token');
        let updateData = await axios.post(`${process.env.REACT_APP_API_URL}api/admin/update-isactive`, {
            isActive: isActive === '1' ? '2' : '1',
            token,
            address
        });

        if (typeof updateData !== 'undefined' && updateData.data.error === 'OK') {
            setRefresh(new Date());
            toast.success('Partner Status successfully updated !');
        }
        else {
            toast.error(updateData.data.error);
        }
    }


    return (
        <section class="pc-container">
            <div class="pcoded-content">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="card">
                            <div class="card-header table-card-header">
                                <h5>HTML5 Export Buttons</h5>
                            </div>
                            <div class="card-body">
                                <div class="dt-responsive table-responsive">
                                    <table id="basic-btn" class="table table-striped table-bordered nowrap">
                                        <thead>
                                            <tr>
                                                <th>No.</th>
                                                <th>Chain</th>
                                                <th>Name</th>
                                                <th>email</th>
                                                <th>address</th>
                                                <th>fees</th>
                                                <th>Status</th>
                                                <th>IsActive</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {partnerArray.map((value, index) => {
                                                let eAddress = supportNetwork[value.chainId] ? supportNetwork[value.chainId].exploreAddress : supportNetwork['default'].exploreAddress
                                                // let tAddress = supportNetwork[value.chainId] ? supportNetwork[value.chainId].exploreTx : supportNetwork['default'].exploreTx
                                                return (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td> {value.chainName ? value.chainName : ''}</td>
                                                        <td>
                                                            {value.name ? value.name : ''}
                                                        </td>
                                                        <td>
                                                            {value.email ? value.email : ''}
                                                        </td>
                                                        <td>
                                                            <a target="_blank" rel="noreferrer" href={`${eAddress}${value.address}`}>{value.address ? value.address.toString().slice(0, 10) + '...' : ''}</a>
                                                        </td>
                                                        <td>
                                                            {value.fees ? formatUnits(value.fees, 'ether') : ''} BNB
                                                        </td>
                                                        <td>
                                                            {value.status === '1' ? (<span class="badge badge-success">Active</span>) : (<span class="badge badge-danger">InActive</span>)}
                                                        </td>
                                                        <td>
                                                            {value.isActive === '1' ? (<span class="badge badge-success">Active</span>) : (<span class="badge badge-danger">InActive</span>)}
                                                        </td>
                                                        <td>
                                                            <Link to={`/edit-partner/${value.address}?chainId=${value.chainId}`} className='badge badge-success mr-2'>Edit</Link>
                                                            {value.isActive === '1' ?
                                                                (<button className='badge badge-danger' onClick={(e) => handleIsActive(e, value.isActive, value.address)}>InActive</button>) :
                                                                (<button className='badge badge-success' onClick={(e) => handleIsActive(e, value.isActive, value.address)}>Active</button>)}

                                                        </td>
                                                    </tr>
                                                )
                                            })}


                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
