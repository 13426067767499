import React from "react";
import { useWeb3React, UnsupportedChainIdError } from "@web3-react/core";
import {
    NoEthereumProviderError
} from "@web3-react/injected-connector";

import { useEffect, useState } from "react";
import { injected, walletconnect, coinbaseWallet, bscwallet } from "../helper/connectors";
import Modal from 'react-bootstrap/Modal';
import { trimAddress } from '../helper/constant';
import useEagerConnect from '../helper/useWeb3';
import wrongNetwork from '../images/wrong-network.png';
import walletarrow from '../images/walletarrow.svg';
import { supportNetwork } from "../helper/network";





export const Connect = function () {
    const context = useWeb3React();
    const { connector, account, activate, deactivate, active, error, chainId } = context;
    const [show, setShow] = useState(false);
   

    // handle logic to recognize the connector currently being activated
    const [activatingConnector, setActivatingConnector] = useState();
    useEagerConnect();
    useEffect(() => {
        if (activatingConnector && activatingConnector === connector) {
            setActivatingConnector(undefined);
        }
    }, [activatingConnector, connector]);


    function getErrorMessage(error) {

        if (error instanceof NoEthereumProviderError) {
            const dappUrl = window.location.href; // TODO enter your dapp URL. 
            let metamaskAppDeepLink = "https://metamask.app.link/dapp/" + dappUrl;
            window.open(metamaskAppDeepLink)
        }
        if (error instanceof UnsupportedChainIdError) {
            return <span className="btn-text" onClick={(e) => switchNetwork(56)}>
                <img src={wrongNetwork} alt="wrong-network" height="17px" width="17px" className="mx-2" />Wrong Network</span>;
        }

        deactivate(injected);
    }

    const activating = (connection) => connection === activatingConnector;
    const connected = (connection) => connection === connector;

    const switchNetwork = (networkid) => {
        try {
            // @ts-ignore
            window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: `0x${networkid.toString(16)}` }]
            });
        } catch (e) {
            console.error(e);
        }
    }

    return (
        <React.Fragment>
            <li class="dropdown pc-h-item">
                <button type="button" className="btn btn-dark mr-2">
                    <span className="btn-text">{(chainId && supportNetwork[chainId]) ? supportNetwork[chainId].name : supportNetwork['default'].name}</span>
                </button>
            </li>
            <li class="dropdown pc-h-item">
                {
                    error &&
                    <button type="button" className="btn btn-dark" onClick={() => {
                        setActivatingConnector();
                    }}>
                        <span className="btn-text">{getErrorMessage(error)}</span>
                    </button>
                }
                {!error &&
                    <>


                        {active && (connected(injected) || connected(walletconnect) || connected(coinbaseWallet)) &&
                            <button type="button" className="btn btn-dark" >

                                <span className="btn-text" onClick={() => {
                                    setActivatingConnector();
                                    deactivate(injected);
                                    deactivate(walletconnect);
                                    deactivate(coinbaseWallet);

                                }} >{account && trimAddress(account)}</span>

                            </button>


                        }
                        {!active && (!connected(injected) || !connected(walletconnect) || !connected(coinbaseWallet)) &&
                            <button type="button" className="btn btn-dark" onClick={() => {
                                setShow(!show);
                            }}>
                                <img src={walletarrow} width="14px" alt="wallet connect" className='mx-2' />
                                {(activating(injected) || activating(walletconnect) || activating(coinbaseWallet)) && <span className="btn-text">Connecting...</span>}
                                {(!activating(injected) || !activating(walletconnect) || !activating(coinbaseWallet)) && <span className="btn-text">Connect</span>}
                            </button>
                        }
                    </>
                }
            </li>


            <Modal
                size="lg"
                show={show}
                onHide={() => setShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Body>
                    <div className="card-content clearfix">
                        <div id="A" className="card left-half" onClick={() => {
                            activate(injected);
                            setShow(false);
                        }}>
                            <div className="mt-auto">
                                <img src="../image/metamask.svg" alt="metamask img" height="50px" width="100px" />
                            </div>
                            <div>
                                <span className="mt-3" >Metamask</span>
                            </div>
                        </div>
                        <div id="B" className="card right-half" onClick={() => {
                            activate(coinbaseWallet);
                            setShow(false);

                        }}>
                            <div className="mt-auto">
                                <img src="../image/coinbase.svg" height="50px" width="100px" alt="trutwallet img" />
                            </div>
                            <div>
                                <span className="mt-3" >Coinbase Wallet</span>
                            </div>
                        </div>
                        <div id="C" className="card left-half" onClick={() => {
                            activate(bscwallet);
                            setShow(false);
                        }}>
                            <div className="mt-auto">
                                <img src="../image/binance.svg" height="50px" width="100px" alt="binance img" />
                            </div>
                            <div>
                                <span className="mt-3" >Binance Wallet</span>
                            </div>
                        </div>
                        <div id="D" className="card right-half" onClick={() => {
                            activate(walletconnect);
                            setShow(false);
                        }}>
                            <div className="mt-auto">
                                <img src="../image/walletconnect.svg" height="50px" width="100px" alt="walletconnect img" />
                            </div>
                            <div>
                                <span className="mt-3" >WalletConnect</span>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
};

export default Connect;