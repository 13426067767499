import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { supportNetwork } from '../helper/network';



export default function KycAuditStatus() {
    let [partnerArray, setPartnerArray] = useState([]);

    useEffect(() => {
        async function fetchData() {
            let partnerData = await axios.get(`${process.env.REACT_APP_API_URL}api/pooldata/getPools`, {
                params: {
                    kyc: 1,
                    audit: 1
                }
            });
            if (typeof partnerData.data !== 'undefined' && partnerData.data.error === 'OK') {
                console.log(partnerData.data.data)
                setPartnerArray(partnerData.data.data)
            }
            else {
                toast.error(partnerData.data.error);
            }
        }
        fetchData();
    }, []);





    return (
        <section class="pc-container">
            <div class="pcoded-content">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="card">
                            <div class="card-header table-card-header">
                                <h5>HTML5 Export Buttons</h5>
                            </div>
                            <div class="card-body">
                                <div class="dt-responsive table-responsive">
                                    <table id="basic-btn" class="table table-striped table-bordered nowrap">
                                        <thead>
                                            <tr>
                                                <th>No.</th>
                                                <th>Chain Name</th>
                                                <th>Pool Address</th>
                                                <th>Pool Type</th>
                                                <th>Kyc</th>
                                                <th>Kyc Status</th>
                                                <th>Audit</th>
                                                <th>Audit Staus</th>
                                                <th>Action</th>
                                                {/* <th>Action</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {partnerArray.map((value, index) => {
                                                let eAddress = supportNetwork[value.chainId] ? supportNetwork[value.chainId].exploreAddress : supportNetwork['default'].exploreAddress
                                                // let tAddress = supportNetwork[value.chainId] ? supportNetwork[value.chainId].exploreTx : supportNetwork['default'].exploreTx
                                                return (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{value.chainName ? value.chainName : ''}</td>
                                                        <td><a target="_blank" rel="noreferrer" href={`${eAddress}${value.poolAddress}`}> {value.poolAddress ? value.poolAddress.toString().slice(0, 10) + '...' : ''}</a></td>
                                                        <td>
                                                            {
                                                                value.ispresale === '0' ? 'Presale' :
                                                                    value.ispresale === '1' ? 'Privatesale' :
                                                                        value.ispresale === '2' ? 'FairLaunch' : ' - '

                                                            }
                                                        </td>
                                                        <td>
                                                            {value.kyc === '1' ? 'Yes' : '-'}
                                                        </td>
                                                        <td>
                                                            {value.kycStatus === '1' ? 'Done' : '-'}
                                                        </td>
                                                        <td>
                                                            {value.audit === '1' ? 'Yes' : '-'}
                                                        </td>
                                                        <td>
                                                            {value.auditStatus === '1' ? 'Done' : '-'}
                                                        </td>
                                                        <td>
                                                            <Link to={`/verify-kyc-audit?address=${value.poolAddress}&kyc=${value.kyc}&audit=${value.audit}`} className='badge badge-success mr-2'>Edit</Link>
                                                        </td>
                                                        {/* <td>
                                                            <Link to={`/edit-partner/${value.address}?chainId=${value.chainId}`} className='badge badge-success mr-2'>Edit</Link>
                                                            {value.isActive === '1' ? 
                                                            (<button className='badge badge-danger' onClick={(e)=>handleIsActive(e,value.isActive,value.address)}>InActive</button>):
                                                            (<button className='badge badge-success' onClick={(e)=>handleIsActive(e,value.isActive,value.address)}>Active</button>) } 
                                                            
                                                        </td> */}
                                                    </tr>
                                                )
                                            })}


                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
