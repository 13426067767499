import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import localStorage from "local-storage";

export default function Profile() {
    const [input, setInput] = useState({
        name: '',
        username: '',
        profileUrl: '',
        password: ''
    });
    const [refresh,setRefresh] = useState(new Date());

    useEffect(() => {
        async function fetchData() {
            let token = localStorage.get('token');
            let response = await axios.post(`${process.env.REACT_APP_API_URL}api/admin/get-profile`, {
                token
            });

            if(typeof response.data !== 'undefined' && response.data.error === 'OK'){
                delete response.data.data['password'];
                setInput({...response.data.data});
            }
            else{
                toast.error('Something went wrong ! try again');
            }
        }

        fetchData();
    }, [refresh])

    const handleOnChangeInput = (e) => {
        e.preventDefault();
        setInput((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        let token = localStorage.get('token');
        let response = await axios.post(`${process.env.REACT_APP_API_URL}api/admin/update-profile`, {
            ...input, token
        });
        if(typeof response.data !== 'undefined' && response.data.error === 'OK'){
            toast.success('Profile Successfully updated !');
            setRefresh(new Date());
        }
        else{
            toast.error('Something went wrong ! try again');
        }
    }


    return (
        <section class="pc-container">
            <div class="pcoded-content">
                <div class="row">
                    <div class="col-md-6">
                        <div class="card profile-card-2">
                            <div class="card-img-block">
                                <img class="img-fluid" src="https://images.pexels.com/photos/946351/pexels-photo-946351.jpeg?w=500&h=650&auto=compress&cs=tinysrgb" alt="Card cap" />
                            </div>
                            <div class="card-body pt-5">
                                <img src={`${input.profileUrl ? input.profileUrl : 'https://randomuser.me/api/portraits/men/64.jpg' }`} alt="profile" class="profile" />
                                <h2 class="card-text">{input.username ? input.username : 'User' }</h2>
                                <h5 class="card-title">{input.name ? input.name : 'User' }</h5>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-header">
                                <h5>Edit Profile</h5>
                            </div>
                            <div class="card-body">
                                <form>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">Name</label>
                                                <input type="text" class="form-control" aria-describedby="emailHelp" name="name" placeholder="enter name" value={input.name} onChange={(e) => handleOnChangeInput(e)} />
                                            </div>

                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="exampleInputPassword1">username</label>
                                                <input type="text" class="form-control" name="username" placeholder="enter username" value={input.username} onChange={(e) => handleOnChangeInput(e)} />
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="exampleInputPassword1">Email<span className='text-danger'>*</span></label>
                                                <input type="text" class="form-control" name="email" placeholder="enter email" value={input.email} readOnly={true} />
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="exampleInputPassword1">Profile Url</label>
                                                <input type="text" class="form-control" name="profileUrl" placeholder="enter profile url" value={input.profileUrl} onChange={(e) => handleOnChangeInput(e)} />
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="exampleInputPassword1">Update Password</label>
                                                <input type="text" class="form-control" name="password" placeholder="enter update password" value={input.password} onChange={(e) => handleOnChangeInput(e)} />
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <button type="button" onClick={(e) => handleSubmit(e)} class="btn  btn-primary">Submit</button>
                                        </div>
                                        
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
