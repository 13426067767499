import React, { useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import Connect from './Connect';
import Context from "../context/Context";
import localStorage from 'local-storage';
import { toast } from 'react-toastify';



export default function Header() {
    const [mobileActive, setMobileActive] = useState(false);
    const { value, setValue } = useContext(Context);
    
    const handleMobileMenu = () => {
        setMobileActive(!mobileActive);
    }

    const handleLogout = (e) => {
        e.preventDefault();
        toast.success('Succesfully logout !!');
        setValue({ login: false });
        localStorage.clear();
        window.location.href = window.location.origin;

    }

    return (
        <React.Fragment>
            <div class="pc-mob-header pc-header">
                <div class="pcm-logo">
                    <img src="../image/Untitled11.png" alt="pics" class="logo logo-lg" />
                </div>
                <div>
                    <Connect />
                </div>
                <div>
                    <button className='btn btn-info' onClick={(e) => handleLogout(e)}>Logout</button>
                </div>

                <div class="pcm-toolbar">
                    <a href="#!" class="pc-head-link" onClick={(e) => handleMobileMenu(e)}>
                        <div class={`hamburger hamburger--arrowturn ${mobileActive ? 'is-active' : ''} `}>
                            <div class="hamburger-box">
                                <div class="hamburger-inner"></div>
                            </div>
                        </div>
                    </a>
                    <a href="#!" class="pc-head-link" id="header-collapse">
                        <i data-feather="more-vertical"></i>
                    </a>
                </div>
            </div>

            <nav class={`pc-sidebar ${mobileActive ? 'mob-sidebar-active' : ''} `}>
                <div class="navbar-wrapper">
                    <div class="m-header">
                        <Link to="/" class="b-brand">
                            <img src="../image/Untitled11.png" alt="logo" class="logo logo-lg" />
                            <img src="../image/Untitled11.png" alt="logo" class="logo logo-sm" />
                            <span class="ml-3">Blockstar</span>
                        </Link>

                    </div>
                    <div class="navbar-content">
                        <ul class="pc-navbar">
                            <li class="pc-item disabled">
                                <Link to="/" class="pc-link "><span class="pc-micon"><i data-feather="home"></i></span><span class="pc-mtext">Dashboard</span>
                                </Link>
                            </li>
                            <li class="pc-item disabled">
                                <Link to="/profile" class="pc-link "><span class="pc-micon"><i data-feather="file-text"></i></span><span class="pc-mtext">Profile</span>
                                </Link>
                            </li>
                            {(value.usertype === 1 || value.usertype === '1') &&
                                <React.Fragment>
                                    <li class="pc-item disabled">
                                        <Link to="/partner-view" class="pc-link "><span class="pc-micon"><i data-feather="file-text"></i></span><span class="pc-mtext">View Partner</span>
                                        </Link>
                                    </li>
                                    <li class="pc-item disabled">
                                        <Link to="/add-partner" class="pc-link "><span class="pc-micon"><i data-feather="file-text"></i></span><span class="pc-mtext">Add Partner</span>
                                        </Link>
                                    </li>
                                    <li class="pc-item disabled">
                                        <Link to="/verify-kyc-audit" class="pc-link "><span class="pc-micon"><i data-feather="file-text"></i></span><span class="pc-mtext">Verify Kyc&Audit</span>
                                        </Link>
                                    </li>
                                    <li class="pc-item disabled">
                                        <Link to="/set-admin" class="pc-link "><span class="pc-micon"><i data-feather="file-text"></i></span><span class="pc-mtext">Set Admin</span>
                                        </Link>
                                    </li>
                                    <li class="pc-item disabled">
                                        <Link to="/audit-kyc-staus" class="pc-link "><span class="pc-micon"><i data-feather="file-text"></i></span><span class="pc-mtext">Audit-Kyc Pools</span>
                                        </Link>
                                    </li>
                                    <li class="pc-item disabled">
                                        <Link to="/featured-pools" class="pc-link "><span class="pc-micon"><i data-feather="file-text"></i></span><span class="pc-mtext">Featured Pools</span>
                                        </Link>
                                    </li>

                                </React.Fragment>
                            }
                            {(value.usertype === 2 || value.usertype === '2') &&
                                <li class="pc-item disabled">
                                    <Link to="/details" class="pc-link ">
                                        <span class="pc-micon"><i data-feather="file-text"></i></span>
                                        <span class="pc-mtext">Update Details</span>
                                    </Link>
                                </li>
                            }
                            <li class="pc-item disabled">
                                <Link to="/partner-transaction" class="pc-link "><span class="pc-micon"><i data-feather="file-text"></i></span><span class="pc-mtext">Partner Transaction</span>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>

            <header class="pc-header ">
                <div class="header-wrapper">

                    <div class="ml-auto">
                        <ul class="list-unstyled">
                            <Connect />
                        </ul>
                        <ul class="list-unstyled ml-3">
                            <button className='btn btn-info' onClick={(e) => handleLogout(e)}>Logout</button>
                        </ul>
                    </div>
                </div>
            </header>
        </React.Fragment>
    )
}
