// This file holds the initial, default values for context
// Note: it's good practice to specify defaults here,
//  but in our case they are overwritten by the values
//  within AppContextProvider


export const defaultValue = {
    login : false,
    usertype : 2,
};

const defaultContext = {
    value: defaultValue,
    setValue: () => {},
};
  
export default defaultContext;


  