import React, { useState } from 'react';
import { useWeb3React } from "@web3-react/core";
import { toast } from 'react-toastify';
import { contract } from '../helper/contract';
import { getContract } from '../helper/contractHelper';
import factoryAbi from '../json/poolFactory.json';
import managerAbi from '../json/poolManager.json';
import { getWeb3 } from '../helper/connectors';
import Button from 'react-bootstrap-button-loader';

export default function SetAdmin() {
    const context = useWeb3React();
    const { account, chainId, library } = context;
    const [input, setInput] = useState({
        poolFactory: '',
        poolManager: ''
    });

    const [loading, setLoading] = useState(false);
    const [mloading, setMloading] = useState(false);


    const handleOnChangeInput = (e) => {
        e.preventDefault();
        setInput((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
    }

    const handleSubmitFactory = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {

            if (account && chainId) {
                let poolContractAddress = contract[chainId] ? contract[chainId].poolFactory : contract['default'].poolFactory;
                let poolManagerContract = getContract(factoryAbi, poolContractAddress, library);
                let tx = await poolManagerContract.transferOwnership(input.poolFactory, { 'from': account });

                const resolveAfter3Sec = new Promise(resolve => setTimeout(resolve, 10000));
                toast.promise(
                    resolveAfter3Sec,
                    {
                        pending: 'Waiting for confirmation 👌',
                    }
                )

                var interval = setInterval(async function () {
                    let web3 = getWeb3(chainId);
                    var response = await web3.eth.getTransactionReceipt(tx.hash);
                    if (response != null) {
                        clearInterval(interval)
                        if (response.status === true) {
                            toast.success('success ! your last transaction is success 👍');
                            setLoading(false);
                        }
                        else if (response.status === false) {
                            toast.error('error ! Your last transaction is failed.');
                            setLoading(false);
                        }
                        else {
                            toast.error('error ! something went wrong.');
                            setLoading(false);
                        }
                    }
                }, 5000);

            }
            else {
                toast.error('please connect to wallet !');
                setLoading(false);
            }

        }
        catch (err) {
            toast.error(err.reason ? err.reason : err.message);
            setLoading(false);
        }
    }
    const handleSubmitManager = async (e) => {
        e.preventDefault();
        setMloading(true);
        try {

            if (account && chainId) {
                let poolContractAddress = contract[chainId] ? contract[chainId].poolmanager : contract['default'].poolmanager;
                let poolManagerContract = getContract(managerAbi, poolContractAddress, library);
                let tx = await poolManagerContract.transferOwnership(input.poolManager, { 'from': account });

                const resolveAfter3Sec = new Promise(resolve => setTimeout(resolve, 10000));
                toast.promise(
                    resolveAfter3Sec,
                    {
                        pending: 'Waiting for confirmation 👌',
                    }
                )

                var interval = setInterval(async function () {
                    let web3 = getWeb3(chainId);
                    var response = await web3.eth.getTransactionReceipt(tx.hash);
                    if (response != null) {
                        clearInterval(interval)
                        if (response.status === true) {
                            toast.success('success ! your last transaction is success 👍');
                            setMloading(false);
                        }
                        else if (response.status === false) {
                            toast.error('error ! Your last transaction is failed.');
                            setMloading(false);
                        }
                        else {
                            toast.error('error ! something went wrong.');
                            setMloading(false);
                        }
                    }
                }, 5000);

            }
            else {
                toast.error('please connect to wallet !');
                setMloading(false);
            }

        }
        catch (err) {
            toast.error(err.reason ? err.reason : err.message);
            setMloading(false);
        }
    }


    return (
        <section class="pc-container">
            <div class="pcoded-content">
                <div class="row">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-header">
                                <h5>Set Admin Wallet</h5>
                            </div>
                            <div class="card-body">
                                <form>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">Admin Wallet(Factory Contract)</label>
                                                <input type="text" class="form-control" aria-describedby="emailHelp" name="poolFactory" placeholder="enter wallet address" value={input.poolFactory} onChange={(e) => handleOnChangeInput(e)} />
                                            </div>

                                        </div>
                                        <div class="col-md-12">
                                            <Button loading={loading}  onClick={(e) => handleSubmitFactory(e)} class="btn btn-primary">Submit</Button>
                                        </div>
                                    </div>
                                </form>
                                <form>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">Admin Wallet(Manager Contract)</label>
                                                <input type="text" class="form-control" aria-describedby="emailHelp" name="poolManager" placeholder="enter wallet address" value={input.poolManager} onChange={(e) => handleOnChangeInput(e)} />
                                            </div>

                                        </div>
                                        <div class="col-md-12">
                                            <Button loading={mloading}  onClick={(e) => handleSubmitManager(e)} class="btn btn-primary">Submit</Button>
                                        </div>
                                        <div class="col-md-12 mt-3">
                                            <span className='text-danger'>*take upto 1 minutes to updated details show</span>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
