import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { toast } from 'react-toastify';
// import { Link } from 'react-router-dom';
import { supportNetwork } from '../helper/network';
import localStorage from 'local-storage';



export default function Featuredpools() {
    let [partnerArray, setPartnerArray] = useState([]);
    const [refresh ,setRefesh] = useState(new Date());

    useEffect(() => {
        async function fetchData() {
            let token = localStorage.get('token');
            let partnerData = await axios.post(`${process.env.REACT_APP_API_URL}api/admin/postFeaturedPools`, {
               token
            });
            if (typeof partnerData.data !== 'undefined' && partnerData.data.error === 'OK') {
                setPartnerArray(partnerData.data.data)
            }
            else {
                toast.error(partnerData.data.error);
            }
        }
        fetchData();
    }, [refresh]);


    const hadleStatusChange =async(e,isFeatured,poolAddress) => {
        e.preventDefault();
        let token = localStorage.get('token');
        let partnerData = await axios.post(`${process.env.REACT_APP_API_URL}api/admin/change-featured-status`, {
            token,isFeatured,poolAddress
         });

         if (typeof partnerData.data !== 'undefined' && partnerData.data.error === 'OK') {
            setRefesh(new Date());
        }
        else {
            toast.error(partnerData.data.error);
        }


    }


    return (
        <section class="pc-container">
            <div class="pcoded-content">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="card">
                            <div class="card-header table-card-header">
                                <h5>HTML5 Export Buttons</h5>
                            </div>
                            <div class="card-body">
                                <div class="dt-responsive table-responsive">
                                    <table id="basic-btn" class="table table-striped table-bordered nowrap">
                                        <thead>
                                            <tr>
                                                <th>No.</th>
                                                <th>Chain Name</th>
                                                <th>Pool Address</th>
                                                <th>Pool Type</th>
                                                <th>IsFeatured</th>
                                                <th>Action</th>
                                                {/* <th>Action</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {partnerArray.map((value, index) => {
                                                let eAddress = supportNetwork[value.chainId] ? supportNetwork[value.chainId].exploreAddress : supportNetwork['default'].exploreAddress
                                                // let tAddress = supportNetwork[value.chainId] ? supportNetwork[value.chainId].exploreTx : supportNetwork['default'].exploreTx
                                                return (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{value.chainName ? value.chainName : ''}</td>
                                                        <td><a target="_blank" rel="noreferrer" href={`${eAddress}${value.poolAddress}`}> {value.poolAddress ? value.poolAddress.toString().slice(0, 10) + '...' : ''}</a></td>
                                                        <td>
                                                            {
                                                                value.ispresale === '0' ? 'Presale' :
                                                                    value.ispresale === '1' ? 'Privatesale' :
                                                                        value.ispresale === '2' ? 'FairLaunch' : ' - '

                                                            }
                                                        </td>
                                                        <td>{
                                                            value.isFeatured === '1' ? 
                                                            <button className='badge badge-success'>Active</button> : 
                                                            <button className='badge badge-danger'>InActive</button> 
                                                            
                                                            }</td>
                                                        <td>
                                                            {value.isFeatured === '1' ?  (
                                                                <button className='btn btn-danger btn-sm' onClick={(e)=>hadleStatusChange(e,value.isFeatured,value.poolAddress)}>InActive</button>
                                                            ) : (
                                                                <button className='btn btn-success btn-sm' onClick={(e)=>hadleStatusChange(e,value.isFeatured,value.poolAddress)}>Active</button>
                                                            )}
                                                            
                                                        </td>
                                                        {/* <td>
                                                            <Link to={`/edit-partner/${value.address}?chainId=${value.chainId}`} className='badge badge-success mr-2'>Edit</Link>
                                                            {value.isActive === '1' ? 
                                                            (<button className='badge badge-danger' onClick={(e)=>handleIsActive(e,value.isActive,value.address)}>InActive</button>):
                                                            (<button className='badge badge-success' onClick={(e)=>handleIsActive(e,value.isActive,value.address)}>Active</button>) } 
                                                            
                                                        </td> */}
                                                    </tr>
                                                )
                                            })}


                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
