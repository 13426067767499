import React, { useState, useEffect } from 'react';
import { useWeb3React } from "@web3-react/core";
import { toast } from 'react-toastify';
import { contract } from '../helper/contract';
import { getContract } from '../helper/contractHelper';
import factoryAbi from '../json/poolFactory.json';
import { getWeb3 } from '../helper/connectors';
import Button from 'react-bootstrap-button-loader';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import localStorage from 'local-storage';

export default function VerifyKycAudit() {
    const context = useWeb3React();
    const { account, chainId, library } = context;
    const [input, setInput] = useState({
        poolAddress: '',
        kyc: false,
        audit: false,
    });
    const search = useLocation().search;

    const [loading, setLoading] = useState(false);
    const [kycLink, setkycLink] = useState('')
    const [auditLink, setAuditLink] = useState('')

    useEffect(() => {
        async function fetchData() {
            const addessget = new URLSearchParams(search).get('address');
            const kycget = new URLSearchParams(search).get('kyc');
            const auditget = new URLSearchParams(search).get('audit');

            if(addessget){

                let partnerData = await axios.get(`${process.env.REACT_APP_API_URL}api/admin/get-kyc-link`, {
                    params: {
                        poolAddress: addessget
                    }
                });

                if (typeof partnerData.data !== 'undefined' && partnerData.data.error === 'OK') {
                    setkycLink(partnerData.data.data.kycLink)
                    setAuditLink(partnerData.data.data.auditLink)
                }
                else {
                    toast.error(partnerData.data.error);
                }

                setInput({ poolAddress: addessget, kyc: kycget ? kycget === '1' ? true : false : false, audit: auditget ? auditget === '1' ? true : false : false });
            }
        }
        fetchData();
        // eslint-disable-next-line
    }, [])


    const handleOnChangeInput = (e) => {
        setInput({ ...input, [e.target.name]: e.target.value });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {

            if (account && chainId) {
                let poolContractAddress = contract[chainId] ? contract[chainId].poolFactory : contract['default'].poolFactory;
                let poolFactoryContract = getContract(factoryAbi, poolContractAddress, library);
                let tx = await poolFactoryContract.updateKycAuditStatus(input.poolAddress, input.kyc, input.audit, { 'from': account });

                const resolveAfter3Sec = new Promise(resolve => setTimeout(resolve, 4000));
                toast.promise(
                    resolveAfter3Sec,
                    {
                        pending: 'Waiting for confirmation 👌',
                    }
                )

                var interval = setInterval(async function () {
                    let web3 = getWeb3(chainId);
                    var response = await web3.eth.getTransactionReceipt(tx.hash);
                    if (response != null) {
                        clearInterval(interval)
                        if (response.status === true) {
                            toast.success('success ! your last transaction is success 👍');
                            setLoading(false);
                        }
                        else if (response.status === false) {
                            toast.error('error ! Your last transaction is failed.');
                            setLoading(false);
                        }
                        else {
                            toast.error('error ! something went wrong.');
                            setLoading(false);
                        }
                    }
                }, 5000);

            }
            else {
                toast.error('please connect to wallet !');
                setLoading(false);
            }
        }
        catch (err) {
            toast.error(err.reason ? err.reason : err.message);
            setLoading(false);
        }
    }

    const handleKycSubmit = async (e) => {
        e.preventDefault();
        let token = localStorage.get('token');
        let response = await axios.post(`${process.env.REACT_APP_API_URL}api/admin/set-kyc-link`, {
            token,
            poolAddress: input.poolAddress,
            auditLink,
            kycLink

        });

        if (typeof response.data !== 'undefined' && response.data.error === 'OK') {
            toast.success('Successfully Updated Links!!');
        }
        else {
            toast.error(response.data.error);
        }


    }

    return (
        <section class="pc-container">
            <div class="pcoded-content">
                <div class="row">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-header">
                                <h5>Add AMA Partner</h5>
                            </div>
                            <div class="card-body">
                                <form>
                                    <div class="row">

                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label for="exampleInputPassword1">Pool Address<span className='text-danger'>*</span></label>
                                                <input type="text" class="form-control" name="poolAddress" value={input.poolAddress} onChange={(e) => handleOnChangeInput(e)} placeholder="enter poolAddress ..." />
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label for="exampleInputPassword1">Kyc Status</label>
                                                <select className='form-control' name="kyc" onChange={(e) => handleOnChangeInput(e)}>
                                                    <option value="false" selected={!input.kyc ? true : false}>Peding</option>
                                                    <option value="true" selected={input.kyc ? true : false}>Completed</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label for="exampleInputPassword1">Audit Status</label>
                                                <select className='form-control' name="audit" onChange={(e) => handleOnChangeInput(e)}>
                                                    <option value="false" selected={!input.audit ? true : false}>Peding</option>
                                                    <option value="true" selected={input.audit ? true : false}>Completed</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <Button loading={loading} onClick={(e) => handleSubmit(e)} class="btn btn-primary">Submit</Button>
                                        </div>
                                    </div>
                                </form>
                                <form>
                                    <div class="row">

                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label for="exampleInputPassword1">Kyc Link<span className='text-danger'>*</span></label>
                                                <input type="text" class="form-control" name="kycLink" value={kycLink} onChange={(e) => setkycLink(e.target.value)} placeholder="enter kyc link ..." />
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label for="exampleInputPassword1">Audit Link<span className='text-danger'>*</span></label>
                                                <input type="text" class="form-control" name="auditlink" value={auditLink} onChange={(e) => setAuditLink(e.target.value)} placeholder="enter kyc link ..." />
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <button onClick={(e) => handleKycSubmit(e)} class="btn btn-primary">Submit</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        </section >
    )
}
