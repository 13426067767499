import React, { useState , useContext } from 'react'
import { toast } from 'react-toastify';
import axios from 'axios';
import localStorage from "local-storage";
import Context from "../context/Context";

export default function Login() {
    const { setValue , value } = useContext(Context);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    

    const handleSubmit = async (e) => {
        e.preventDefault();
        try{
            if (email !== '' && password !== '') {
                let response = await axios.post(`${process.env.REACT_APP_API_URL}api/admin/login`, {
                    email,
                    password
                });
                
                if (typeof response.data != 'undefined'  && response.data.error === 'OK') {
                    let token = response.data.token
                    localStorage('token', token);
                    setValue({ ...value ,'login' : true , 'usertype' : response.data.usertype })
                    toast.success('successfully login !');
                }
                else {
                    toast.error(response.data.error ? response.data.error : 'invalid request send' );
                    setValue({'login' : false})
                }

            }
            else {
                toast.error('Please Enter valid Details');
                setValue({'login' : false})
            }
        }
        catch(err){
            toast.error(err.reason ? err.reason : err.message);
            setValue({'login' : false})
        }
    }

    return (
        <div class="global-container" style={{ "marginTop": "20px" }}>
            <div class="card login-form">
                <div class="card-body">
                    <h3 class="card-title text-center">Log in to Blockstar</h3>
                    <div class="card-text">
                        <form>
                            {/* <!-- to error: add class "has-danger" --> */}
                            <div class="form-group">
                                <label for="exampleInputEmail1">Email address</label>
                                <input type="email" class="form-control form-control-sm" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Email .." value={email} onChange={(e) => { setEmail(e.target.value) }} />
                            </div>
                            <div class="form-group">
                                <label for="exampleInputPassword1">Password</label>
                                <input type="password" class="form-control form-control-sm" id="exampleInputPassword1" placeholder='Enter Password ..' value={password} onChange={(e) => { setPassword(e.target.value) }} />
                            </div>
                            <button onClick={(e) => { handleSubmit(e) }} class="btn btn-primary btn-block">Sign in</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>

    )
}
