import React, { useState, useEffect } from 'react';
import { useWeb3React } from "@web3-react/core";
import { toast } from 'react-toastify';
import { contract } from '../helper/contract';
import { getContract } from '../helper/contractHelper';
import managerAbi from '../json/poolManager.json';
import axios from 'axios';
import { getWeb3 } from '../helper/connectors';
import Button from 'react-bootstrap-button-loader';
import { parseEther } from 'ethers/lib/utils';
import localStorage from 'local-storage';

export default function PartnerProfile() {
    const context = useWeb3React();
    const { account, chainId, library } = context;
    const [error_msg, setError_msg] = useState('');
    const [btndisabled, setBtndisabled] = useState(false);
    const [input, setInput] = useState({
        name: '',
        username: '',
        profileUrl: '',
        password: '',
        newaddress: '',
        fees: 0,
        status : 2,
        kyc : false,
        audit : false,
        address : ''
    });
    const [refresh, setRefresh] = useState(new Date());
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        async function fetchData() {
            let token = localStorage.get('token');
            let response = await axios.post(`${process.env.REACT_APP_API_URL}api/admin/get-profile`, {
                token,
                chainId
            });

            if (typeof response.data !== 'undefined' && response.data.error === 'OK') {
                delete response.data.data['password'];
                if(Object.keys(response.data.data).length > 0){
                    setInput({ ...response.data.data, fees: response.data.data.fees / Math.pow(10, 18) });
                }
                else{
                    toast.error('No Profile Found With Current Chainid!!');
                    setInput({
                        name: '',
                        username: '',
                        profileUrl: '',
                        password: '',
                        newaddress: '',
                        fees: 0,
                        status : 2,
                        kyc : false,
                        audit : false,
                        address : ''
                    })  
                }
            }
            else {
                toast.error(response.data.error);
                
            }
        }

        

        fetchData();
    }, [refresh,chainId])



    const handleOnChangeInput = (e) => {
        e.preventDefault();
        if (e.target.name === 'fees') {
            if (isNaN(e.target.value)) {
                setError_msg('please enter valid amount');
                setBtndisabled(true);
            }

            else if (e.target.value === '') {
                setError_msg('amount must be greater than zero');
                setBtndisabled(true);
            }
            else {
                setError_msg('');
                setBtndisabled(false);
            }
        }
        setInput((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            if (input.email !== '' && typeof input.email !== 'undefined' && input.address !== '' && typeof input.address !== 'undefined' && account.toLowerCase() === input.address.toLowerCase()) {
                if (account && chainId) {
                    let poolContractAddress = contract[chainId] ? contract[chainId].poolmanager : contract['default'].poolmanager;
                    let poolManagerContract = getContract(managerAbi, poolContractAddress, library);
                    let response = {};
                    if (typeof input.password !== 'undefined' && input.password !== '') {
                        response = await axios.post(`${process.env.REACT_APP_API_URL}api/admin/password-crypt`, {
                            password: input.password
                        });
                    }
                    else {
                        let token = localStorage.get('token');
                        response = await axios.post(`${process.env.REACT_APP_API_URL}api/admin/get-profile`, {
                            token,chainId
                        });
                    }


                    if (typeof response.data !== 'undefined' && response.data.error === 'OK') {
                        let encrypt_pass = response.data.password ? response.data.password : response.data.data.password;
                        let updatedAddress = input.newaddress !== '' && input.newaddress ? input.newaddress : '0x0000000000000000000000000000000000000000';
                        let tx = await poolManagerContract.updatePartnerInfo(input.name, input.username, parseEther(input.fees.toString()), encrypt_pass, input.status === '1' ? true : false , input.kyc === '1' ? true : false , input.audit === '1' ? true : false ,updatedAddress, { 'from': account, "value": 0 });

                        const resolveAfter3Sec = new Promise(resolve => setTimeout(resolve, 10000));
                        toast.promise(
                            resolveAfter3Sec,
                            {
                                pending: 'Waiting for confirmation 👌',
                            }
                        )

                        var interval = setInterval(async function () {
                            let web3 = getWeb3(chainId);
                            var response = await web3.eth.getTransactionReceipt(tx.hash);
                            if (response != null) {
                                clearInterval(interval)
                                if (response.status === true) {
                                    toast.success('success ! your last transaction is success 👍');
                                    setLoading(false);
                                    setRefresh(new Date());
                                }
                                else if (response.status === false) {
                                    toast.error('error ! Your last transaction is failed.');
                                    setLoading(false);
                                }
                                else {
                                    toast.error('error ! something went wrong.');
                                    setLoading(false);
                                }
                            }
                        }, 5000);
                    }
                    else {
                        toast.error(response.data.error);
                        setLoading(false);
                    }
                }
                else {
                    toast.error('please connect to wallet !');
                    setLoading(false);
                }
            }
            else {
                toast.error('please enter valid details or connected wallet is not register !!');
                setLoading(false);
            }
        }
        catch (err) {
            toast.error(err.reason ? err.reason : err.message);
            setLoading(false);
        }
    }


    return (
        <section class="pc-container">
            <div class="pcoded-content">
                <div class="row">
                    <div class="col-md-6">
                        <div class="card profile-card-2">
                            <div class="card-img-block">
                                <img class="img-fluid" src="https://images.pexels.com/photos/946351/pexels-photo-946351.jpeg?w=500&h=650&auto=compress&cs=tinysrgb" alt="Card cap" />
                            </div>
                            <div class="card-body pt-5">
                                <img src={`${input.profileUrl ? input.profileUrl : 'https://randomuser.me/api/portraits/men/64.jpg'}`} alt="profile" class="profile" />
                                <h2 class="card-text">{input.username ? input.username : 'User'}</h2>
                                <h5 class="card-title">{input.name ? input.name : 'User'}</h5>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-header">
                                <h5>Edit Profile</h5>
                            </div>
                            <div class="card-body">
                                <form>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">Name</label>
                                                <input type="text" class="form-control" aria-describedby="emailHelp" name="name" placeholder="enter name" value={input.name} onChange={(e) => handleOnChangeInput(e)} />
                                            </div>

                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="exampleInputPassword1">username</label>
                                                <input type="text" class="form-control" name="username" placeholder="enter username" value={input.username} onChange={(e) => handleOnChangeInput(e)} />
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="exampleInputPassword1">Email<span className='text-danger'>*</span></label>
                                                <input type="text" class="form-control" name="email" placeholder="enter email" value={input.email} readOnly={true} />
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="exampleInputPassword1">Price(In BNB)<span className='text-danger'>*</span></label>
                                                <input type="text" class="form-control" name="fees" placeholder="enter Price" value={input.fees} onChange={(e) => handleOnChangeInput(e)} />
                                                <span className='text-danger text-sm'>{error_msg}</span>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="exampleInputPassword1">Profile Image</label>
                                                <input type="text" class="form-control" name="profileUrl" placeholder="enter profile image url" value={input.profileUrl} onChange={(e) => handleOnChangeInput(e)} />
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="exampleInputPassword1">Status</label>
                                                <select className='form-control' name="status" onChange={(e) => handleOnChangeInput(e)}>
                                                    <option value="1" selected={input.status === '1' ? true : false}>Active</option>
                                                    <option value="2" selected={input.status === '2' ? true : false}>InActive</option>
                                                </select>
                                            </div>
                                        </div>
                                        {/* <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="exampleInputPassword1">KYC Service</label>
                                                <select className='form-control' name="kyc" onChange={(e) => handleOnChangeInput(e)}>
                                                    <option value="1" selected={input.kyc === '1' ? true : false}>Active</option>
                                                    <option value="2" selected={input.kyc === '2' ? true : false}>InActive</option>
                                                </select>
                                            </div>
                                        </div> */}
                                        {/* <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="exampleInputPassword1">AUDIT Service</label>
                                                <select className='form-control' name="audit" onChange={(e) => handleOnChangeInput(e)}>
                                                    <option value="1" selected={input.audit === '1' ? true : false}>Active</option>
                                                    <option value="2" selected={input.audit === '2' ? true : false}>InActive</option>
                                                </select>
                                            </div>
                                        </div> */}
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="exampleInputPassword1">Update Password</label>
                                                <input type="text" class="form-control" name="password" placeholder="enter update password" value={input.password} onChange={(e) => handleOnChangeInput(e)} />
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="exampleInputPassword1">Update Address</label>
                                                <input type="text" class="form-control" name="newaddress" placeholder="enter update address" value={input.newaddress} onChange={(e) => handleOnChangeInput(e)} />
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <Button loading={loading} disabled={btndisabled} onClick={(e) => handleSubmit(e)} class="btn btn-primary">Submit</Button>
                                        </div>
                                        <div class="col-md-12 mt-3">
                                            <span className='text-danger'>*take upto 1 minutes to updated details show</span>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
