import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import localStorage from 'local-storage';
import { supportNetwork } from '../../helper/network';

export default function PartnerTransaction() {
    const [history, setHistory] = useState([]);

    useEffect(() => {
        async function fetchData() {
            let token = localStorage.get('token');
            let response = await axios.post(`${process.env.REACT_APP_API_URL}api/admin/get-pools`, {
                token
            });

            if (typeof response.data !== 'undefined' && response.data.error === 'OK') {
                setHistory(response.data.data)

            }
            else {
                toast.error(response.data.error);
            }
        }

        fetchData();
    }, [])

    return (
        <section class="pc-container">
            <div class="pcoded-content">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="card">
                            <div class="card-header table-card-header">
                                <h5>Recent Transactions</h5>
                            </div>
                            <div class="card-body">
                                <div class="dt-responsive table-responsive">
                                    <table id="basic-btn" class="table table-striped table-bordered nowrap">
                                        <thead>
                                            <tr>
                                                <th>No.</th>
                                                <th>Partner Address</th>
                                                <th>Paid Fees</th>
                                                <th>Pool Address</th>
                                                <th>chain Name</th>
                                                <th>Status</th>
                                                <th>Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {history.map((rowdata, index) => {
                                                let eAddress = supportNetwork[rowdata.chainId] ? supportNetwork[rowdata.chainId].exploreAddress : supportNetwork['default'].exploreAddress
                                                // let tAddress = supportNetwork[rowdata.chainId] ? supportNetwork[rowdata.chainId].exploreTx : supportNetwork['default'].exploreTx
                                                
                                                return (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td><a target="_blank" rel="noreferrer" href={`${eAddress}${rowdata.address}`}>{rowdata.address.toString().slice(0, 10)}...</a></td>
                                                        <td>
                                                            {parseFloat(rowdata.fees / Math.pow(10, 18))} BNB
                                                        </td>
                                                        <td>
                                                            <a target="_blank" rel="noreferrer" href={`${eAddress}${rowdata.poolAddrss}`}>{rowdata.poolAddrss.toString().slice(0, 10)}...</a>
                                                        </td>
                                                        <td>
                                                            {rowdata.chainName}
                                                        </td>
                                                        <td>
                                                            <span class="badge badge-success">Success</span>
                                                        </td>
                                                        <td>
                                                            {new Date(rowdata.time * 1000).toUTCString().substring(4, 25)}
                                                        </td>
                                                    </tr>
                                                )
                                            })}


                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}


