import React from 'react'

export default function Footer() {
    return (
        <div class="page-footer">
            <div class="page-footer-inner"> 
                Copyright &copy; BlockStar 2022 
            </div>
            <div class="scroll-to-top">
                <i class="icon-arrow-up"></i>
            </div>
        </div>
    )
}
