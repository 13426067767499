import React, { useState } from 'react';
import { useWeb3React } from "@web3-react/core";
import { toast } from 'react-toastify';
import { contract } from '../../helper/contract';
import { getContract } from '../../helper/contractHelper';
import managerAbi from '../../json/poolManager.json';
import axios from 'axios';
import { getWeb3 } from '../../helper/connectors';
import Button from 'react-bootstrap-button-loader';
import { parseEther } from 'ethers/lib/utils';

export default function AddPartner() {
    const context = useWeb3React();
    const { account, chainId, library } = context;
    const [error_msg, setError_msg] = useState('');
    const [btndisabled, setBtndisabled] = useState(false);
    const [input, setInput] = useState({
        name: '',
        username: '',
        email: '',
        profile: '',
        address: '',
        fees: 0,
        status: true,
        kyc : false,
        audit : false
    });

    const [loading, setLoading] = useState(false);
    const [inputFields, setInputFields] = useState([{
        service: 'AMA',
    }]);

    const addInputField = () => {

        setInputFields([...inputFields, {
            service: '',
        }])

    }
    const removeInputFields = (index, e) => {
        e.preventDefault();
        const rows = [...inputFields];
        rows.splice(index, 1);
        setInputFields(rows);
    }
    const handleChange = (index, evnt) => {
        const { name, value } = evnt.target;
        const list = [...inputFields];
        list[index][name] = value;
        setInputFields(list);
    }

    const handleOnChangeInput = (e) => {
        e.preventDefault();
        if (e.target.name === 'fees') {
            if (isNaN(e.target.value)) {
                setError_msg('please enter valid amount');
                setBtndisabled(true);
            }

            else if (e.target.value === '') {
                setError_msg('amount must be greater than zero');
                setBtndisabled(true);
            }
            else {
                setError_msg('');
                setBtndisabled(false);
            }
        }
        setInput({ ...input, [e.target.name]: e.target.value });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            if (input.email !== '' && typeof input.email !== 'undefined' && input.address !== '' && typeof input.address !== 'undefined' && inputFields.length > 0) {
                if (account && chainId) {
                    let poolContractAddress = contract[chainId] ? contract[chainId].poolmanager : contract['default'].poolmanager;
                    let poolManagerContract = getContract(managerAbi, poolContractAddress, library);

                    var pass = '';
                    var str = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ' +
                        'abcdefghijklmnopqrstuvwxyz0123456789@#$';

                    for (let i = 1; i <= 8; i++) {
                        var char = Math.floor(Math.random()
                            * str.length + 1);

                        pass += str.charAt(char)
                    }

                    let response = await axios.post(`${process.env.REACT_APP_API_URL}api/admin/password-crypt`, {
                        password: pass
                    });

                    if (typeof response.data !== 'undefined' && response.data.error === 'OK') {
                        let encrypt_pass = response.data.password;
                        const rows = [...inputFields];
                        let serviceString = rows.map((product) => product.service.replace(/\s+/g,'')).join('  +  ');
                        let tx = await poolManagerContract.registerAma(input.name, input.username, input.address, input.email, parseEther(input.fees), input.profile, encrypt_pass, serviceString, input.status, input.kyc , input.audit ,{ 'from': account });

                        const resolveAfter3Sec = new Promise(resolve => setTimeout(resolve, 4000));
                        toast.promise(
                            resolveAfter3Sec,
                            {
                                pending: 'Waiting for confirmation 👌',
                            }
                        )

                        var interval = setInterval(async function () {
                            let web3 = getWeb3(chainId);
                            var response = await web3.eth.getTransactionReceipt(tx.hash);
                            if (response != null) {
                                clearInterval(interval)
                                if (response.status === true) {
                                    let emailPass = await axios.post(`${process.env.REACT_APP_API_URL}api/admin/sendPass-mail`, {
                                        password: pass,
                                        email: input.email
                                    });
                                    if (typeof emailPass.data !== 'undefined' && emailPass.data.error === 'OK') {
                                        toast.success('success ! Id and Password send to Partner email.');
                                    }
                                    else {
                                        toast.error(emailPass.data.error);

                                    }
                                    toast.success('success ! your last transaction is success 👍');
                                    setLoading(false);
                                }
                                else if (response.status === false) {
                                    toast.error('error ! Your last transaction is failed.');
                                    setLoading(false);
                                }
                                else {
                                    toast.error('error ! something went wrong.');
                                    setLoading(false);
                                }
                            }
                        }, 5000);
                    }
                    else {
                        toast.error(response.data.error);
                        setLoading(false);
                    }
                }
                else {
                    toast.error('please connect to wallet !');
                    setLoading(false);
                }
            }
            else {
                toast.error('please enter valid details !');
                setLoading(false);
            }
        }
        catch (err) {
            toast.error(err.reason ? err.reason : err.message);
            setLoading(false);
        }
    }

    return (
        <section class="pc-container">
            <div class="pcoded-content">
                <div class="row">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-header">
                                <h5>Add AMA Partner</h5>
                            </div>
                            <div class="card-body">
                                <form action="#" method="post">
                                    <div class="row">

                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="exampleInputPassword1">Email<span className='text-danger'>*</span></label>
                                                <input type="text" class="form-control" name="email" value={input.email} onChange={(e) => handleOnChangeInput(e)} placeholder="enter email" />
                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="exampleInputPassword1">Wallet Address<span className='text-danger'>*</span></label>
                                                <input type="text" class="form-control" name="address" placeholder="enter wallet address" value={input.address} onChange={(e) => handleOnChangeInput(e)} />
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="exampleInputPassword1">Price(In BNB)<span className='text-danger'>*</span></label>
                                                <input type="text" class="form-control" name="fees" placeholder="enter Price" value={input.fees} onChange={(e) => handleOnChangeInput(e)} />
                                                <span className='text-danger text-sm'>{error_msg}</span>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="exampleInputPassword1">Profile Image</label>
                                                <input type="text" class="form-control" name="profile" placeholder="enter profile Image url" value={input.profile} onChange={(e) => handleOnChangeInput(e)} />
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">Name</label>
                                                <input type="text" class="form-control" aria-describedby="emailHelp" name="name" placeholder="enter name" value={input.name} onChange={(e) => handleOnChangeInput(e)} />
                                            </div>

                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="exampleInputPassword1">username</label>
                                                <input type="text" class="form-control" name="username" placeholder="enter username" value={input.username} onChange={(e) => handleOnChangeInput(e)} />
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="exampleInputPassword1">Status</label>
                                                <select className='form-control' name="status" onChange={(e) => handleOnChangeInput(e)}>
                                                    <option value="true" selected={input.status ? true : false}>Active</option>
                                                    <option value="false" selected={!input.status ? true : false}>InActive</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="exampleInputPassword1">KYC Service</label>
                                                <select className='form-control' name="kyc" onChange={(e) => handleOnChangeInput(e)}>
                                                    <option value="true" selected={input.kyc ? true : false}>Active</option>
                                                    <option value="false" selected={!input.kyc ? true : false}>InActive</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="exampleInputPassword1">AUDIT Service</label>
                                                <select className='form-control' name="audit" onChange={(e) => handleOnChangeInput(e)}>
                                                    <option value="true" selected={input.audit ? true : false}>Active</option>
                                                    <option value="false" selected={!input.audit ? true : false}>InActive</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <label for="exampleInputPassword1">Service Details<span className='text-danger'>*</span></label>
                                            {
                                                inputFields.map((data, index) => {
                                                    const { service } = data;
                                                    return (
                                                        <div className="row" key={index}>
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <input type="text" onChange={(evnt) => handleChange(index, evnt)} value={service} name="service" className="form-control" placeholder="Enter service details.." />
                                                                </div>
                                                            </div>

                                                            <div className="col">
                                                                {(inputFields.length !== 1) ? <button className="btn btn-danger" onClick={(e) => removeInputFields(index, e)}>x</button> : ''}
                                                            </div>
                                                        </div>
                                                    )
                                                })

                                            }
                                            <div className="col-md-12">
                                                <button className="btn btn-outline-success" type="button" onClick={(e) => addInputField(e)}>Add New</button>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <Button loading={loading} disabled={btndisabled} onClick={(e) => handleSubmit(e)} class="btn btn-primary">Submit</Button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        </section >
    )
}
