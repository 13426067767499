import React, { useState, useEffect, useContext } from "react";
import Context from "./context/Context";
import { defaultValue } from './context/defaults';
import Main from "./Main";



function App() {
  const appContext = useContext(Context);
  const [context, setContext] = useState(appContext);
  const setValue = value => {
    setContext({ ...context, value });
  };

  useEffect(() => {
    setContext({ ...context, value: { ...defaultValue } });
    // eslint-disable-next-line
  }, [])

  const state = {
    ...context,
    setValue: setValue,
  };


  return (
    <Context.Provider value={state}>
      <div className="App">
        <Main />
      </div>
    </Context.Provider>
  );
}

export default App;
