import whitepaper from '../images/ri_file-paper-2-fill.png';
import contract from '../images/teenyicons_contract-solid.png';
import coinmarketcap from '../images/coinmarketcap.png';
import twitter from '../images/twitter.png';
import fly from '../images/fly.png';


export const trimAddress = (addr) => {
  return `${addr.substring(0, 6) }...${addr.substring(addr.length - 4)}`;
}

export const socialLinks = [
  // {
  //   name : "website",
  //   image : "fa-solid fa-globe"
  // },
  {
    name : "whitepaper",
    image : whitepaper,
    link : "#sec"
  },
  {
    name : "contract",
    image : contract,
    link : "#sec"
  },
  {
    name : "coinmarketcap",
    image : coinmarketcap,
    link : "#sec"
  },
  {
    name : "twitter",
    image : twitter,
    link : "#sec"
  },
  {
    name : "fly",
    image : fly,
    link : "#sec"
  }
]
  


