export const supportNetwork = { 
    1 : {
        name : "ETHEREUM",
        chainId : 1,
        rpc : "https://mainnet.infura.io/v3/63f4b8ee61284419b46c780d03befc4e",
        image : "etherum.png",
        symbol : 'ETH'
    },
    56 : {
        name : "BINANCE",
        chainId : 56,
        rpc : "https://bsc-dataseed1.defibit.io/",
        image : "binance-coin.svg",
        symbol : 'BNB',
        exploreAddress : "https://bscscan.com/address/",
        exploreTx : "https://bscscan.com/tx/"
    },
    137 : {
        name : "POLYGON",
        chainId : 137,
        rpc : "https://polygon-rpc.com/",
        image : "polygon.svg",
        symbol : 'MATIC',
        exploreAddress : "https://polygonscan.com/address/",
        exploreTx : "https://polygonscan.com/tx/"
    },
    43114 : {
        name : "Avalanche",
        chainId : 43114,
        rpc : "https://api.avax.network/ext/bc/C/rpc",
        image : "avalanche.svg",
        symbol : 'AVAX',
        exploreAddress : "https://snowtrace.io/address/",
        exploreTx : "https://snowtrace.io/tx/"
    },
    'default':{
        name : "BINANCE",
        chainId : 56,
        rpc : "https://bsc-dataseed1.defibit.io/",
        image : "binance-coin.svg",
        symbol : 'BNB',
        exploreAddress : "https://bscscan.com/address/",
        exploreTx : "https://bscscan.com/tx/"
    }

}

export const RPC_URLS = {
    56: "https://bsc-dataseed.binance.org/",
    // 97: "https://data-seed-prebsc-1-s1.binance.org:8545/",
    // 3: "https://ropsten.infura.io/v3/63f4b8ee61284419b46c780d03befc4e",
    // 4: "https://rinkeby.infura.io/v3/63f4b8ee61284419b46c780d03befc4e",
    43114 : "https://api.avax.network/ext/bc/C/rpc",
    137 : "https://polygon-rpc.com/",
    1 : "https://mainnet.infura.io/v3/63f4b8ee61284419b46c780d03befc4e"
  };

