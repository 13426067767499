import React, { useState, useEffect } from 'react';
import { useWeb3React } from "@web3-react/core";
import { toast } from 'react-toastify';
import { contract } from '../../helper/contract';
import { getContract } from '../../helper/contractHelper';
import managerAbi from '../../json/poolManager.json';
import axios from 'axios';
import { getWeb3 } from '../../helper/connectors';
import Button from 'react-bootstrap-button-loader';
import localStorage from "local-storage";
import {
    useHistory,
    useLocation
} from "react-router-dom";

export default function EditPartner() {
    let history = useHistory();
    const context = useWeb3React();
    const { account, chainId, library } = context;
    const [input, setInput] = useState({
        name: '',
        username: '',
        email: '',
        profileUrl: '',
        address: '',
        newaddress: ''
    });

    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState('1');
    const search = useLocation().search;
    const queryChainId = new URLSearchParams(search).get('chainId');

    useEffect(() => {
        async function fetchData() {
            let token = localStorage.get('token');
            const segments = new URL(window.location.href).pathname.split('/');
            const address = segments.pop() || segments.pop(); // Handle potential trailing slash
            // console.log(last);
            // let address = window.location.href.toString().split("/").pop();


            let response = await axios.post(`${process.env.REACT_APP_API_URL}api/admin/get-address-profile`, {
                token, address, chainId: queryChainId ? queryChainId : chainId
            });

            if (typeof response.data !== 'undefined' && response.data.error === 'OK') {
                delete response.data.data['password'];
                setInput({ ...response.data.data });
            }
            else {
                toast.error('Something went wrong ! try again');
            }
        }

        fetchData();
        // eslint-disable-next-line
    }, [refresh, chainId])

    const handleOnChangeInput = (e) => {
        e.preventDefault();
        setInput({ ...input, [e.target.name]: e.target.value });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            if (input.email !== '' && typeof input.email !== 'undefined' && input.address !== '' && typeof input.address !== 'undefined') {
                if (account && chainId) {
                    let poolContractAddress = contract[chainId] ? contract[chainId].poolmanager : contract['default'].poolmanager;
                    let poolManagerContract = getContract(managerAbi, poolContractAddress, library);


                    let tx = await poolManagerContract.updatePartnerAddress(input.newaddress, input.address, { 'from': account, "value": 0 });

                    const resolveAfter3Sec = new Promise(resolve => setTimeout(resolve, 10000));
                    toast.promise(
                        resolveAfter3Sec,
                        {
                            pending: 'Waiting for confirmation 👌',
                        }
                    )

                    var interval = setInterval(async function () {
                        let web3 = getWeb3(chainId);
                        var response = await web3.eth.getTransactionReceipt(tx.hash);
                        if (response != null) {
                            clearInterval(interval)
                            if (response.status === true) {
                                toast.success('success ! your last transaction is success 👍');
                                setLoading(false);
                                history.push('/partner-view');

                            }
                            else if (response.status === false) {
                                toast.error('error ! Your last transaction is failed.');
                                setLoading(false);
                            }
                            else {
                                toast.error('error ! something went wrong.');
                                setLoading(false);
                                setRefresh(new Date());
                            }
                        }
                    }, 5000);

                }
                else {
                    toast.error('please connect to wallet !');
                    setLoading(false);
                }
            }
            else {
                toast.error('please enter valid details !');
                setLoading(false);
            }
        }
        catch (err) {
            toast.error(err.reason ? err.reason : err.message);
            setLoading(false);
        }
    }

    return (
        <section class="pc-container">
            <div class="pcoded-content">
                <div class="row">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-header">
                                <h5>Edit AMA Partner</h5>
                            </div>
                            <div class="card-body">
                                <form action="#" method="post">
                                    <div class="row">

                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="exampleInputPassword1">Email<span className='text-danger'>*</span></label>
                                                <input type="text" class="form-control" name="email" readOnly={true} value={input.email} onChange={(e) => handleOnChangeInput(e)} placeholder="enter email" />
                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="exampleInputPassword1">Wallet Address<span className='text-danger'>*</span></label>
                                                <input type="text" class="form-control" readOnly={true} name="address" placeholder="enter wallet address" value={input.address} onChange={(e) => handleOnChangeInput(e)} />
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="exampleInputPassword1">New Wallet Address<span className='text-danger'>*</span></label>
                                                <input type="text" class="form-control" name="newaddress" placeholder="enter wallet address" value={input.newaddress} onChange={(e) => handleOnChangeInput(e)} />
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="exampleInputPassword1">Profile Url</label>
                                                <input type="text" class="form-control" name="profileUrl" readOnly={true} placeholder="enter profile url" value={input.profileUrl} onChange={(e) => handleOnChangeInput(e)} />
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">Name</label>
                                                <input type="text" class="form-control" aria-describedby="emailHelp" readOnly={true} name="name" placeholder="enter name" value={input.name} onChange={(e) => handleOnChangeInput(e)} />
                                            </div>

                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="exampleInputPassword1">username</label>
                                                <input type="text" class="form-control" name="username" readOnly={true} placeholder="enter username" value={input.username} onChange={(e) => handleOnChangeInput(e)} />
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <Button loading={loading} onClick={(e) => handleSubmit(e)} class="btn btn-primary">Submit</Button>
                                        </div>
                                        <div class="col-md-12 mt-3">
                                            <span className='text-danger'>*take upto 1 minutes to updated details show</span>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
