import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import localStorage from 'local-storage';
import { supportNetwork } from '../helper/network';


export default function Dashboard() {
    const [history, setHistory] = useState([]);

    useEffect(() => {
        async function fetchData() {
            let token = localStorage.get('token');
            let response = await axios.post(`${process.env.REACT_APP_API_URL}api/admin/get-partnerProfile`, {
                token
            });

            if (typeof response.data !== 'undefined' && response.data.error === 'OK') {
                console.log(response.data.data);
                setHistory(response.data.data)

            }
            else {
                toast.error(response.data.error);
            }
        }

        fetchData();
    }, [])

    return (
        <section class="pc-container">
            <div class="pcoded-content">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="card">
                            <div class="card-header table-card-header">
                                <h5>All Profile List</h5>
                            </div>
                            <div class="card-body">
                                <div class="dt-responsive table-responsive">
                                    <table id="basic-btn" class="table table-striped table-bordered nowrap">
                                        <thead>
                                            <tr>
                                                <th>No.</th>
                                                <th>chain Name</th>
                                                <th>Email</th>
                                                <th>Partner Address</th>
                                                <th>details</th>
                                                <th>isActive</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {history.map((rowdata, index) => {
                                                let eAddress = supportNetwork[rowdata.chainId] ? supportNetwork[rowdata.chainId].exploreAddress : supportNetwork['default'].exploreAddress
                                                // let tAddress = supportNetwork[value.chainId] ? supportNetwork[value.chainId].exploreTx : supportNetwork['default'].exploreTx
                                                return (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{rowdata.chainName}</td>
                                                        <td>
                                                            {rowdata.email}
                                                        </td>
                                                        <td>
                                                            <a target="_blank" rel="noreferrer" href={`${eAddress}${rowdata.address}`}>{rowdata.address.toString().slice(0, 10)}...</a>
                                                        </td>
                                                        <td>
                                                            {rowdata.details}
                                                        </td>
                                                        <td>
                                                            {rowdata.isActive === '1' ? <span class="badge badge-success">Active</span> : <span class="badge badge-success">InActive</span> }
                                                        </td>
                                                        <td>
                                                            {rowdata.status === '1' ? <span class="badge badge-success">Active</span> : <span class="badge badge-success">InActive</span> }
                                                        </td>
                                                    </tr>
                                                )
                                            })}


                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}


